import React from 'react'
import PageTitle from '../components/PageTitle'
import { Elements } from 'react-stripe-elements'
import ThanksgivingOrderForm from '../components/DinnerOrderForm'
import formItems from '../data/forms/thanksgiving'
import { postThanksGivingOrder } from '../utils/api'
import { plantin, plantinBoldItalic } from '../styles'
import Layout from '../components/layout'
import styled from '@emotion/styled'
import BuildingImage from '../assets/images/svg/mags-building.svg'

const orderItems = [
  'Sage Butter Rubbed Twelve Pound Turkey with Giblet Gravy.',
  'Roasted Garlic Whipped Potatoes',
  'Sherry Mushroom Green Bean Casserole with Crispy Onions ',
  'Bourbon Maple Glazed Carrots',
  'Herbed Focaccia and Sausage Stuffing',
  'Grand Marnier Cranberry Sauce',
  'ESH Local Greens Salad, Cabernet Vinaigrette, Grana Padano, Baby Heirloom Tomatoes, English Cucumbers',
  'Magnolia’s Focaccia Bread',
  'Choice of Dessert: Mag’s Dutch Apple Pie or Pumpkin Pie',
]

const OrderFormCopy = styled.p({
  fontFamily: plantin,
  maxWidth: '75ch',
  margin: '0 auto 2rem auto',
  marginTop: 0,
  '& em': {
    fontFamily: plantinBoldItalic,
  },
})

const reducer = (accumulator, currentValue) => {
  accumulator[currentValue.name] = currentValue.price ? 0 : ''
  return accumulator
}

const totalFunc = state => {
  const isGlutenFree = state.order.dinnerType === 'glutenFree'
  return (
    []
      .concat(...formItems.map(item => item.items))
      .map(item =>
        item.name === 'dinnerQty'
          ? { ...item, price: isGlutenFree ? 325 : 300 }
          : item
      )
      .map(item => (item.price ? state.order[item.name] * item.price : 0))
      .reduce((accumulator, currentValue) => accumulator + currentValue) +
    (['appleGF', 'pecanGF'].includes(state.order.pie) ? 5 : 0)
  )
}

const thanksGivingOrderInitialState = []
  .concat(...formItems.map(item => item.items))
  .map(item => ({ name: item.name, price: item.price ? item.price : null }))
  .reduce(reducer, {})

const SoldOutWrap = styled.div`
  text-align: center;
  min-height: 50vh;
  padding: 0 1rem;
`

const ImageWrapper = styled.div(
  {
    marginBottom: '3rem',
    maxWidth: '80%',
    padding: '0 2rem',
    margin: '0 auto',
  },
  () => ({
    '@media screen and (min-width: 720px)': {
      maxWidth: '30%',
    },
  })
)

const WineWrapper = styled.div`
  display: grid;
  margin: 2rem 0;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
`

const Copy = (
  <>
    <OrderFormCopy>
      Order your Thanksgiving Turkey Dinner from Magnolias this year and leave
      the cooking to us! Each order feeds up to six people. Orders can be picked
      up <em>between 10AM and Noon</em> on Thanksgiving Day at Magnolias.
    </OrderFormCopy>
    <OrderFormCopy>
      <strong>For just $300, each order includes:</strong>
    </OrderFormCopy>
  </>
)

const WineLList = (
  <OrderFormCopy>
    <WineWrapper>
      <div>
        <h3>Sparkling Wine</h3>
        <ul>
          <li>Veuve de Vernay Sparkling (France) $15</li>
          <li>Secco Rosé (Austria) $20</li>
          <li>Laurent-Perrier Rosé (France) $99</li>
        </ul>
      </div>
      <div>
        <h3>Red Wine</h3>
        <ul>
          <li>Firestead Pinot Noir (Williamette Valley, OR) $20</li>
          <li>Lapis Luna Zinfandel (North Coast, CA) $20</li>
          <li>Ancient Peaks Cabernet (Paso Robles, CA) $20</li>
          <li>Adelsheim Pinot Noir (Williamette, OR) $75</li>
          <li>Cakebread 2018 (Napa, CA) $99</li>
        </ul>
      </div>
      <div>
        <h3>White Wine</h3>
        <ul>
          <li>Annalisa Pinot Grigio (Veneto, IT) $15</li>
          <li>Emmolo Sauvignon Blanc (CA) $20</li>
          <li>Decoy Chardonnay (CA) $20</li>
          <li>Chassagne-Montrachet, Thomas Morey (France) $99</li>
        </ul>
      </div>
    </WineWrapper>
  </OrderFormCopy>
)
const ThanksgivingOrderPage = () => (
  <Layout>
    <PageTitle header="Thanksgiving By Magnolias" />
    {/*

    <SoldOutWrap>
      <ImageWrapper>
        <BuildingImage />
      </ImageWrapper>
      <p>
        Sorry, we are now sold out of Thanksgiving Orders. To be placed on a waitlist,
        please call us at <a href="tel5403389800">(540) 338–9800</a>
      </p>
 </SoldOutWrap> */}

    <Elements>
      <ThanksgivingOrderForm
        top={Copy}
        // bottom={WineLList}
        orderItems={orderItems}
        formItems={formItems}
        totalFunc={totalFunc}
        thankYouLink={'/thanksgiving-thank-you/'}
        postOrderApi={postThanksGivingOrder}
        initialState={{
          ...thanksGivingOrderInitialState,
          dinnerQty: 1,
          pie: 'apple',
          dinnerType: 'traditional',
        }}
      ></ThanksgivingOrderForm>
    </Elements>
  </Layout>
)

export default ThanksgivingOrderPage
